import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { getApiURL } from '../Config';
import { withGetScreen } from 'react-getscreen';

class KaigieRedirector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointments: [],

      appointmentsFound: false,
      apiInfo: {},
      apiKey: '',
      sessionId: '',
      uid: '',
      expertFlg: '',
      token: '',
      expertUserId: 0,
      redirect: false,
      redirectURL: ''
    };

    this.isMobile = { withGetScreen };
  }

  //   hideHeadingDuringChat = status => {
  //     this.props.location.state.hideHeadingDuringChat(status);
  //   };

  getApiData = async (session_id, uid, expert_flg, expert_user_id) => {
    const appointmentURI =
      getApiURL() + '/tbsession/' + session_id + '/session';
    axios
      .get(appointmentURI, { timeout: 15000 })
      .then(res => {
        console.log('Return From API Call');
        console.log(res.data);
        const incoming = res.data[0];
        debugger;
        const apiInfo = {
          apiKey: incoming.apiKey,
          session_id: incoming.session_id,
          token: incoming.token,
          apiKeyInt: incoming.apiKeyInt
        };
        //debugger;

        this.setState({
          apiKey: incoming.apiKey,
          sessionId: session_id,
          uid: uid,
          token: incoming.token,
          expertUserId: expert_user_id
        });
        //debugger;
        const { user_screen_name } = this.props.match.params;
        this.setState({
          apiInfo: apiInfo,
          credentialsPresent: true,
          redirectURL: `/chatsessionmainsite/${user_screen_name}/${session_id}/${incoming.apiKey}/${incoming.token}`,
          redirectMobile: `/chatsessionmobile/${session_id}/${incoming.apiKey}/${incoming.token}`
        });

        //debugger;
      })
      .then(() => {
        console.log('session api call successful');
        this.setState({ redirect: true });
      })
      .catch(function(error) {
        console.log(error);
        return 'Error Getting Session Info';
      });
    console.log('Finished running getApiData()');

    return 'Obtained Session Info';
  };

  componentWillMount() {
    const {
      session_id,
      id,
      uid,
      user_screen_name,
      expert_flg,
      expert_user_id
    } = this.props.match.params;
    var currentSession = {
      session_id: session_id,
      id: id,
      uid: uid,
      user_screen_name: user_screen_name,
      expert_flg: expert_flg,
      expert_user_id: expert_user_id
    };
    debugger;
    sessionStorage.setItem(session_id, JSON.stringify(currentSession));
    sessionStorage.setItem('redirect', 'Y');
    debugger;
    console.log(
      'incoming session info saved in local storage formatted fro chat2'
    );
  }

  componentDidMount() {
    const {
      session_id,
      id,
      uid,
      user_screen_name,
      expert_flg,
      expert_user_id
    } = this.props.match.params;
    console.log('In componentDidMount');

    this.setState({ publisherProperties: { name: user_screen_name } });
    this.setState({ name: user_screen_name });

    var chatSessionInfo = {};
    chatSessionInfo = JSON.parse(sessionStorage.getItem(session_id));
    console.log('chatSessionInfo');
    console.log(chatSessionInfo);
    // get apiKey, Token, etc.
    this.getApiData(session_id, uid, expert_flg, expert_user_id);
  }

  renderRedirect = () => {
    debugger;
    var mobileDevice = () => {
      this.props.isMobile();
    };
    debugger;
    var lowerUA = navigator.userAgent.toLowerCase();

    if (lowerUA.indexOf('wv') >= 0) {
      sessionStorage.setItem('isWebview', 'Y');
    } else if (
      (lowerUA.indexOf('iphone') >= 0 || lowerUA.indexOf('iPad') >= 0) &&
      lowerUA.indexOf('safari') < 0
    ) {
      sessionStorage.setItem('isWebview', 'Y');
    } else {
      sessionStorage.setItem('isWebview', 'N');
    }

    alert('Browser Is Webview: ' + sessionStorage.getItem('isWebview'));

    if (mobileDevice === true) {
      return <Redirect to={this.state.redirectMobile} />;
    } else {
      return <Redirect to={this.state.redirectURL} />;
    }
  };

  getRedirect = () => {
    var mobileDevice = () => {
      this.props.isMobile();
    };

    var lowerUA = navigator.userAgent.toLowerCase();

    if (lowerUA.indexOf('wv') >= 0) {
      sessionStorage.setItem('isWebview', 'Y');
      alert('Browser is webview');
    } else if (
      (lowerUA.indexOf('iphone') >= 0 || lowerUA.indexOf('iPad') >= 0) &&
      lowerUA.indexOf('safari') < 0
    ) {
      sessionStorage.setItem('isWebview', 'Y');
      alert('Browser is webview');
    } else {
      sessionStorage.setItem('isWebview', 'N');
    }
    if (mobileDevice === true) {
      return this.state.redirectMobile;
    } else {
      return this.state.redirectURL;
    }
  };
  render() {
    //var hideHeadingDuringChat = this.props.hideHeadingDuringChat;
    const redirectTo = this.getRedirect();
    return (
      <div className="container">
        <div>Hi</div>
        <div className="w-100" />
        {/* <button onClick={() => hideHeadingDuringChat(true)}>
          Test Function
        </button> */}
        <span>Please Wait...</span>
        {this.state.redirect && <Redirect to={redirectTo} />}
      </div>
    );
  }
}

export default KaigieRedirector;
